<template>
    <div>
        <b-form-row>
            <!-- name -->
            <b-form-group
                :id="locale + '.group-record.name'"
                :label="$t('admin.right.status_name')"
                :label-for="locale + '.name'"
                class="col-sm-12 col-md-6"
                description=""
                label-class="mb-0"
            >
                <b-form-input
                    :id="locale + '.name'"
                    v-model="record.name"
                    :formatter="__capitalize"
                ></b-form-input>
            </b-form-group>
        </b-form-row>
        <!-- notes -->
        <b-form-group
            id="group-record.locale.notes"
            :label="$t('form.notes')"
            class="col-sm-12 p-0"
            description=""

            label-class="mb-0"
            label-for="record.locale.notes"
        >
            <vue-editor
                v-model="record.notes"
            ></vue-editor>
        </b-form-group>
    </div>
</template>

<script>
export default {
    name: "FormRightLocale",
    components: {},
    props: {
        locale: '',
        record: {},
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
