<template>
    <div>
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-container class="mt-3" fluid>
                <!-- table toolbar -->
                <table-toolbar
                    :columns-filter="tableToolbarColumns"
                    :columns-show="tableToolbarColumns"
                    :locale-key="localeKey"
                    :options="tableToolbar.options"
                    :response.sync="tableToolbar.response"
                    @eventNew="toolbarTableEventNew"
                    @eventRefresh="toolbarTableEventRefresh"
                ></table-toolbar>
                <!-- table -->
                <b-table
                    id="main-table"
                    :current-page="tableToolbar.options.pagination.currentPage"
                    :fields="tableColumns"
                    :filter="tableToolbar.response.filter === undefined ? null : tableToolbar.response.filter.search"
                    :filterIncludedFields="tableToolbar.response.filter === undefined ? null : tableToolbar.response.filter.columnsOn"
                    :items="tableItems"
                    :per-page="tableToolbar.options.pagination.perPage"
                    :sort-by.sync="table.sortBy"
                    :sort-desc.sync="table.sortDesc"
                    bordered head-row-variant="dark" hover outlined
                    responsive="sm"
                    striped
                    table-class="text-center"
                    table-variant="light"
                    @filtered="onFiltered"
                >
                    <!-- status -->
                    <template v-slot:cell(status)="row">
                        <div class="d-flex justify-content-start">
                            {{row.item.status}}
                        </div>
                    </template>
                    <!-- system -->
                    <template v-slot:cell(system)="row">
                        <div class="d-flex justify-content-around">
                            <b-icon-check
                                v-if="row.item.system"
                                scale="1.2"
                                style="margin-right: 3px;"
                                variant="secondary"
                            ></b-icon-check>
                        </div>
                    </template>
                    <!-- active -->
                    <template v-slot:cell(active)="row">
                        <div class="d-flex justify-content-around">
                            <b-icon-sun
                                :variant="row.item.active ? 'success' : 'danger'"
                                scale="1.2"
                            ></b-icon-sun>
                        </div>
                    </template>
                    <!-- actions -->
                    <template v-slot:cell(action)="row">
                        <div class="d-flex justify-content-around">
                            <!-- detail show -->
                            <b-icon-caret-down-square
                                v-if="!row.detailsShowing"
                                v-b-tooltip.hover
                                :title="$t('form.detail_show')"
                                scale="1.2"
                                style="cursor: pointer; margin-right: 3px;"
                                variant="secondary"
                                @click="row.toggleDetails"
                            ></b-icon-caret-down-square>
                            <!-- detail hide -->
                            <b-icon-caret-up-square-fill
                                v-if="row.detailsShowing"
                                v-b-tooltip.hover
                                :title="$t('form.detail_hide')"
                                scale="1.2"
                                style="cursor: pointer; margin-right: 3px;"
                                variant="secondary"
                                @click="row.toggleDetails"
                            ></b-icon-caret-up-square-fill>
                            <!-- edit -->
                            <b-icon-file-text
                                v-if="!row.item.system"
                                v-b-tooltip.hover
                                :title="$t('form.edit')"
                                scale="1.2"
                                style="cursor: pointer; margin-right: 3px;"
                                variant="secondary"
                                @click="recordEdit(row.item)"
                            ></b-icon-file-text>
                            <!-- delete -->
                            <b-icon-trash
                                v-if="!row.item.system"
                                v-b-tooltip.hover
                                :title="$t('form.delete')"
                                scale="1.2"
                                style="cursor: pointer;"
                                variant="danger"
                                @click="recordDelete(row.item)"
                            ></b-icon-trash>
                        </div>
                    </template>
                    <!-- row detail -->
                    <template v-slot:row-details="row">
                        <TableDetailHtml :content="tableRowDetail(row.item.status_id)"></TableDetailHtml>
                    </template>
                </b-table>
                <!-- pagination -->
                <b-pagination
                    v-model="tableToolbar.options.pagination.currentPage"
                    :per-page="tableToolbar.options.pagination.perPage"
                    :total-rows="this.tableToolbar.options.filterCount"
                    aria-controls="main-table"
                    limit="20"
                    size="sm"
                ></b-pagination>
            </b-container>
        </b-overlay>
        <!-- modalFormXxx -->
        <modal-form-right
            :form="form"
            :locale-key="localeKey"
            :uri="uri"
            @refresh="modalEventRefresh"
        ></modal-form-right>
    </div>
</template>

<script>
import TableToolbar from "@/components/general/elements/tableAccessories/TableToolbar";
import ModalFormRight from "@/components/admin/right/ModalFormRight";
import TableDetailHtml from "@/components/general/elements/tableAccessories/TableDetailHtml";

export default {
    name: "Rights",
    components: {TableDetailHtml, ModalFormRight, TableToolbar},
    props: {},
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            localeKey: 'admin.right.main',
            uri: {
                main: `api/v1/admin/rights`,
                uri1: `api/v1/admin/right`,
            },
            table: {
                items: [],
                fields: [],
                sortBy: 'id',
                sortDesc: false,
            },
            tableToolbar: {
                options: {
                    filterCount: 0,
                    rowsCount: 0,
                    pagination: {
                        perPage: 10,
                        currentPage: 1,
                    },
                },
                response: {}
            },
            form: {},
            // <-- set -->
            data: [],
            record: {},
        }
    },
    mounted() {
        this.baseSelect();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- toolbarTable -->
        toolbarTableEventRefresh() {
            this.baseSelect();
        },
        toolbarTableEventNew() {
            this.recordNew();
        },
        // <-- modalForm -->
        modalEventRefresh(data) {
            this.$set(this, 'data', data);
        },
        // <-- table -->
        onFiltered(filteredItems) {
            this.tableToolbar.options.filterCount = filteredItems.length;
            this.tableToolbar.options.pagination.currentPage = 1;
        },
        tableRowDetail(id) {
            let status = this.data.statuses.find((n) => {
                if (n.id === id) {
                    return n;
                }
            });
            if (status.locale[this.$i18n.locale] !== undefined) {
                return status.locale[this.$i18n.locale].notes;
            }
        },
        // <-- record -->
        recordNew() {
            this.$set(this.form, 'status_id', 0);
            this.$bvModal.show('ModalFormRight');
        },
        recordEdit(row) {
            this.$set(this.form, 'status_id', row.status_id);
            this.$bvModal.show('ModalFormRight');
        },
        recordDelete(row) {
            const h = this.$createElement;
            const messageHTML = h('div', [
                h('span', [
                    `${this.$t('form.delete_message.pre')} ${this.$tc(this.localeKey, 2)} `,
                    h('strong', row.status), this.$t('form.delete_message.post'),
                ])
            ]);

            // <-- message box -->
            this.$bvModal.msgBoxConfirm([messageHTML], {
                title: this.$t('form.please_confirm'),
                // size: 'sm',
                // buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('form.delete'),
                cancelTitle: this.$t('form.cancel'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        this.baseDelete(row.status_id);
                    }
                })
                .catch(err => {
                    // on error executed
                })
        },
        // <-- api calls -->
        baseSelect: function () {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.main, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                    this.showOverlay(false);
                });
        },
        baseDelete(record_id) {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.delete(this.uri.main + `/${record_id}`, config)
                .then((response) => {
                    this.data = response.data;
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
    },
    computed: {
        // <-- tableToolbar -->
        tableToolbarColumns() {
            let columns = this.data.accesses;
            // <-- check if exists -->
            if (columns === undefined) {
                return [];
            }
            // <--  -->
            let values = [];
            // <-- add column status_id -->
            values.push({
                key: 'status_id',
                label: this.$t('form.id'),
                id: 0,
                sortable: false,
                show: false
                // variant: 'danger'
            });
            // <-- add column status -->
            values.push({
                key: 'status',
                label: this.$t('form.status'),
                id: 0,
                sortable: false,
                show: false,
                alwaysShow: true
                // variant: 'danger'
            });
            // <-- add column system -->
            values.push({
                key: 'system',
                label: this.$t('admin.system'),
                id: 0,
                sortable: true,
                show: false
                // variant: 'danger'
            });
            // <-- add column order -->
            values.push({
                key: 'order',
                label: this.$t('form.order'),
                id: 0,
                sortable: true,
                show: false
                // variant: 'danger'
            });
            // <-- add column active -->
            values.push({
                key: 'active',
                label: this.$t('form.active'),
                id: 0,
                sortable: true,
                show: false
                // variant: 'danger'
            });
            // <-- add columns -->
            let label = '';
            columns.forEach(column => {
                label = column.meta.name;
                Object.keys(column.locale).find((n) => {
                    if (n === this.$i18n.locale) {
                        if (!this.__isNull(column.locale[n].name)) {
                            label = column.locale[n].name;
                        }
                    }
                });
                values.push({
                    key: column.stamp,
                    label: this.__capitalize(label),
                    show: true
                });
            });
            // <--  -->
            this.table.fields = values;
            return values;
        },
        // <-- table -->
        tableColumns() {
            let columns = this.data.accesses;
            let columnsShow = this.tableToolbar.response.columnsShow;

            // <-- check if exists -->
            if (columns === undefined) {
                return [];
            }
            // <--  -->
            let values = [];
            // <-- add column status_id -->
            if (columnsShow != null && columnsShow.indexOf('status_id') >= 0) {
                values.push({
                    key: 'status_id',
                    label: this.$t('form.id'),
                    id: 0,
                    sortable: true,
                    // variant: 'danger'
                });
            }
            // <-- add column status -->
            values.push({
                key: 'status',
                label: this.$t('form.status'),
                id: 0,
                sortable: true,
                // variant: 'danger'
            });
            // <-- add column system -->
            if (columnsShow != null && columnsShow.indexOf('system') >= 0) {
                values.push({
                    key: 'system',
                    label: this.$t('admin.system'),
                    id: 0,
                    sortable: true,
                    // variant: 'danger'
                });
            }
            // <-- add column order -->
            if (columnsShow != null && columnsShow.indexOf('order') >= 0) {
                values.push({
                    key: 'order',
                    label: this.$t('form.order'),
                    id: 0,
                    sortable: true,
                    // variant: 'danger'
                });
            }
            // <-- add column active -->
            if (columnsShow != null && columnsShow.indexOf('active') >= 0) {
                values.push({
                    key: 'active',
                    label: this.$t('form.active'),
                    id: 0,
                    sortable: true,
                    // variant: 'danger'
                });
            }
            // <-- add columns -->
            let label = '';
            columns.forEach(column => {
                label = column.meta.name;
                Object.keys(column.locale).find((n) => {
                    if (n === this.$i18n.locale) {
                        if (!this.__isNull(column.locale[n].name)) {
                            label = column.locale[n].name;
                        }
                    }
                });
                if (columnsShow != null && columnsShow.indexOf(column.stamp) >= 0) {
                    values.push({
                        key: column.stamp,
                        label: this.__capitalize(label),
                        id: column.id,
                        sortable: true,
                        // variant: 'danger'
                    });
                }
            });
            // <-- add column actions -->
            values.push({
                key: 'action',
                label: '',
                id: 0,
                sortable: false,
                thStyle: {width: '90px'}    //85
                // variant: 'danger',
            });
            // <--  -->
            this.table.fields = values;
            return values;
        },
        tableItems() {
            let sadmin_id = 1, admin_id = 2;
            // <--  -->
            let rights = this.data.rights;
            let columns = this.tableColumns;
            let statuses = this.data.statuses;
            // <-- check if exists -->
            if (rights === undefined || statuses === undefined || columns.length === 0) {
                return [];
            }
            // <--  -->
            let items = [];
            let item = {};
            let statusName = '';

            this.data.statuses.forEach(status => {
                statusName = '';
                item = {};

                columns.forEach(column => {
                    // <-- status name -->
                    if (column.id === 0 && column.key === 'status') {
                        statusName = status.meta.name;
                        Object.keys(status.locale).find((n) => {
                            if (n === this.$i18n.locale) {
                                if (!this.__isNull(status.locale[n].name)) {
                                    statusName = status.locale[n].name;
                                }
                            }
                            this.$set(item, 'status', statusName);
                            this.$set(item, 'access_id', column.id);
                        });
                    }
                    // <-- rights -->
                    if (column.id > 0) {
                        let right = '';
                        // <-- sadmin, admin -->
                        if (status.id === sadmin_id || status.id === admin_id) {
                            right = 'RW';
                        }
                        // <-- other rights -->
                        rights.find((n) => {
                            // <-- sadmin, admin -->
                            // if (status.id === sadmin_id || status.id === admin_id) {
                            //     right = 'RW';
                            // }
                            // <--  -->
                            if (n.access_id === column.id && n.status_id === status.id) {
                                Object.keys(n.right).forEach(key => {
                                    if (n.right[key]) {
                                        right += key.substr(0, 1).toUpperCase();
                                    }
                                });
                            }
                        });
                        this.$set(item, 'status_id', status.id);
                        this.$set(item, 'system', status.system);
                        this.$set(item, 'order', status.order);
                        this.$set(item, 'active', status.active);
                        this.$set(item, column.key, right);
                    }
                });
                // <-- push item -->
                items.push(item);
            });
            // <--  -->
            this.tableToolbar.options.filterCount = items.length;
            this.tableToolbar.options.rowsCount = items.length;
            return items;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
