<template>
    <b-modal
        :id="$options.name"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        :title="title"
        header-bg-variant="primary"
        size="xl"
        @ok="formSave"
        @show="modalOpen"
    >
        <b-overlay v-if="record !== null" :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-form ref="form">
                <!-- id, active -->
                <form-id-active :record.sync="record"></form-id-active>
                <!-- left tabs -->
                <b-card no-body>
                    <b-tabs v-model="tabIndex" card pills vertical>
                        <!-- tab basic -->
                        <b-tab active>
                            <template #title>
                                <b-icon v-if="!tabValidBasic"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('form.tab.basic')}}
                            </template>
                            <!-- meta -->
                            <b-form-group
                                :label="$tc('form.meta.data', 0)"
                                class="mb-0 group-title p-0"
                                label-align-sm="left"
                                label-class="font-weight-bold p-0 pl-1"
                                label-cols-sm="12"
                                label-size=""
                            >
                                <b-form-row>
                                    <!-- status name -->
                                    <b-form-group
                                        id="group-meta.name"
                                        :label="$t('admin.right.status_name')"
                                        class="col-sm-12 col-md-6"
                                        description=""
                                        label-class="mb-0"
                                        label-for="meta.name"
                                    >
                                        <b-form-input
                                            id="meta.name"
                                            v-model="record.meta.name"
                                            :formatter="__capitalize"
                                            :placeholder="$t('form.required_field')"
                                            :state="valid_metaStatusName"
                                            required
                                            @change="createStamp()"
                                        ></b-form-input>
                                        <b-form-invalid-feedback :state="valid_metaStatusName">
                                            {{$t('form.required_field')}}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                    <!-- stamp -->
                                    <b-form-group
                                        id="group-meta.name"
                                        :label="$t('form.stamp')"
                                        class="col-sm-12 col-md-6"
                                        description=""
                                        label-class="mb-0"
                                        label-for="meta.name"
                                    >
                                        <b-form-input
                                            id="meta.name"
                                            v-model="record.stamp"
                                            :placeholder="$t('form.required_field')"
                                            readonly
                                            required
                                        ></b-form-input>
                                    </b-form-group>
                                </b-form-row>
                            </b-form-group>
                            <!-- content -->
                            <b-form-group
                                :label="$t('form.content')"
                                class="mb-0 group-title p-0"
                                label-align-sm="left"
                                label-class="font-weight-bold"
                                label-cols-sm="12"
                                label-size=""
                            >
                                <b-form-row>
                                    <!-- order -->
                                    <b-form-group
                                        id="group-order"
                                        :label="$t('form.order')"
                                        class="col-sm-6 col-md-3"
                                        description=""
                                        label-class="mb-0"
                                        label-for="order"
                                    >
                                        <b-form-input
                                            id="order"
                                            v-model="record.order"
                                            type="number"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-form-row>
                            </b-form-group>
                        </b-tab>
                        <!-- tab locale -->
                        <b-tab :title="$t('form.tab.locale')">
                            <!-- locale-->
                            <b-form-group
                                :label="$t('form.locale.locale')"
                                class="mb-0 group-title p-0"
                                label-align-sm="left"
                                label-class="font-weight-bold p-0 pl-1"
                                label-cols-sm="12"
                                label-size=""
                            >
                                <b-card no-body>
                                    <b-tabs card pills>
                                        <b-tab v-for="locale in data.locale"
                                               :active="locale.locale === $i18n.locale"
                                               :title="locale.locale" style="background: white">
                                            <form-right-locale
                                                :locale="locale.locale"
                                                :record.sync="setRecordLocale[locale.locale]"
                                            ></form-right-locale>
                                        </b-tab>
                                    </b-tabs>
                                </b-card>
                            </b-form-group>
                        </b-tab>
                        <!-- tab rights -->
                        <b-tab :title="$t('admin.right.tab.right')" title-link-class="">
                            <b-table
                                id="table-rights"
                                :fields="table.columns"
                                :items="table.items"
                                bordered head-row-variant="dark" hover outlined
                                responsive="sm"
                                striped
                                table-class="text-center"
                                table-variant="light"
                            >
                                <!-- extended row -->
                                <template #thead-top="data">
                                    <b-tr>
                                        <b-th colspan="2" variant=""></b-th>
                                        <b-th colspan="2" variant="secondary">{{$t('admin.right.personal_post')}}</b-th>
                                        <b-th colspan="2" variant="danger">{{$t('admin.right.all_post')}}</b-th>
                                        <b-th colspan="1" variant=""></b-th>
                                    </b-tr>
                                </template>
                                <!-- access -->
                                <template v-slot:cell(access)="row">
                                    <div class="d-flex justify-content-start">
                                        {{row.item.access}}
                                    </div>
                                </template>
                                <!-- read -->
                                <template v-slot:cell(read)="row">
                                    <div class="d-flex justify-content-around">
                                        <b-form-checkbox v-model="row.item.read"/>
                                    </div>
                                </template>
                                <!-- write -->
                                <template v-slot:cell(write)="row">
                                    <div class="d-flex justify-content-around">
                                        <b-form-checkbox v-model="row.item.write"/>
                                    </div>
                                </template>
                                <!-- publish -->
                                <template v-slot:cell(publish)="row">
                                    <div class="d-flex justify-content-around">
                                        <b-form-checkbox v-model="row.item.publish"/>
                                    </div>
                                </template>
                                <!-- edit -->
                                <template v-slot:cell(edit)="row">
                                    <div class="d-flex justify-content-around">
                                        <b-form-checkbox v-model="row.item.edit"/>
                                    </div>
                                </template>
                                <!-- moderator -->
                                <template v-slot:cell(moderator)="row">
                                    <div class="d-flex justify-content-around">
                                        <b-form-checkbox v-model="row.item.moderator"/>
                                    </div>
                                </template>
                                <!-- action -->
                                <template v-slot:cell(action)="row">
                                    <div class="d-flex justify-content-around">
                                        <!-- detail show -->
                                        <b-icon-caret-down-square
                                            v-if="!row.detailsShowing"
                                            v-b-tooltip.hover
                                            :title="$t('form.detail_show')"
                                            scale="1.2"
                                            style="cursor: pointer; margin-right: 3px;"
                                            variant="secondary"
                                            @click="row.toggleDetails"
                                        />
                                        <!-- detail hide -->
                                        <b-icon-caret-up-square-fill
                                            v-if="row.detailsShowing"
                                            v-b-tooltip.hover
                                            :title="$t('form.detail_hide')"
                                            scale="1.2"
                                            style="cursor: pointer; margin-right: 3px;"
                                            variant="secondary"
                                            @click="row.toggleDetails"
                                        />
                                    </div>
                                </template>
                                <!-- row detail -->
                                <template v-slot:row-details="row">
                                    <TableDetailHtml :content="tableRowDetail(row.item.locale)"></TableDetailHtml>
                                </template>
                            </b-table>
                        </b-tab>
                    </b-tabs>
                </b-card>
                <!--  -->
            </b-form>
        </b-overlay>
        <!-- alert error -->
        <b-alert
            v-if="errors !== null"
            :show="errors.message != null"
            class="mb-0"
            dismissible
            variant="danger"
            @dismissed="resetErrors"
        >
            <div v-html="__formatErrorMessage(errors.message)"></div>
        </b-alert>
    </b-modal>
</template>

<script>

import FormRightLocale from "@/components/admin/right/FormRightLocale";
import TableDetailHtml from "@/components/general/elements/tableAccessories/TableDetailHtml";
import FormIdActive from "@/components/general/elements/formElements/FormIdActive";

export default {
    name: "ModalFormRight",
    components: {FormIdActive, TableDetailHtml, FormRightLocale},
    props: {
        localeKey: null,
        uri: null,
        form: {},
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            tabIndex: 0,
            valid: null,
            table: {
                columns: [
                    {
                        key: 'access',
                        label: this.$tc('admin.access.main', 1),
                        sortable: true,
                    },
                    {
                        key: 'read',
                        label: this.$t('admin.access.read'),
                        sortable: true,
                    },
                    {
                        key: 'write',
                        label: this.$t('admin.access.write'),
                        sortable: true,
                        variant: "secondary"
                    },
                    {
                        key: 'publish',
                        label: this.$t('admin.access.publish'),
                        sortable: true,
                        variant: "secondary"
                    },
                    {
                        key: 'edit',
                        label: this.$t('admin.access.edit'),
                        sortable: true,
                        variant: "danger"
                    },
                    {
                        key: 'moderator',
                        label: this.$t('admin.access.moderator'),
                        sortable: true,
                        variant: "danger"
                    },
                    {
                        key: 'action',
                        label: '',
                        sortable: false,
                        thStyle: {width: '45px'}    //85
                    },
                ],
                items: [],
                fields: [],
                sortBy: 'id',
                sortDesc: false,
            },
            recordDefault: {
                status_id: 0,
                active: true,
                order: 0,
                stamp: null,
                meta: {
                    name: '',
                },
                locale: {},
            },
            // <-- set -->
            errorsDefault: {
                message: null,
            },
            errors: null,
            data: {},
            record: null,
        }
    },
    mounted() {
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetErrors() {
            this.$set(this, 'errors', this.__clone(this.errorsDefault));
        },
        createStamp() {
            let basis = this.record.meta.name;
            if (this.__isNull(basis) || this.record.status_id !== 0) {
                this.record.stamp = null;
                return;
            }
            // <-- create stamp -->
            let stamp = basis.toLowerCase();
            stamp = stamp.replace(/ +(?= )/g, '');
            stamp = stamp.split(' ').join('_');
            // <--  -->
            this.record.stamp = stamp;
        },
        // <-- table -->
        tableRowDetail(locale) {
            let detail = null;
            Object.keys(locale).find((n) => {
                if (n === this.$i18n.locale) {
                    detail = locale[n].notes;
                }
            });
            return detail;
        },
        // <-- modal -->
        modalOpen() {
            this.resetErrors();
            this.valid = null;
            // <--  -->
            if (this.form.status_id > 0) {
                this.loadEdit();
                return;
            }
            this.loadNew();
        },
        modalClose() {
            this.showOverlay(false);
        },
        hideModal(data) {
            this.$emit('refresh', data);
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide(this.$options.name);
            });
        },
        // <-- form -->
        checkValidity() {
            this.valid = this.$refs.form.checkValidity();
            return this.valid;
        },
        formSave(bvModalEvent) {
            bvModalEvent.preventDefault();
            if (this.checkValidity()) {
                if (this.record.id > 0) {
                    this.baseUpdate();
                    return;
                }
                this.baseInsert();
            }
        },
        // <-- api -->
        loadNew() {
            this.showOverlay(true);
            // <-- record -->
            this.$set(this, 'record', this.__clone(this.recordDefault));
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.uri1 + `/getForeignData`, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    this.$set(this.table, 'items', this.tableItems);
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // on error executed
                })
                .finally(function () {
                    // always executed
                });
        },
        loadEdit() {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.main + `/${this.form.status_id}`, config)
                .then((response) => {
                    // <-- status -->
                    this.$set(this, 'record', response.data.status);
                    // <-- rights -->
                    this.$set(this, 'data', response.data.data);
                    // <--  -->
                    this.$set(this.table, 'items', this.tableItems);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
        // <-- base -->
        baseInsert() {
            this.showOverlay(true);
            // <--  -->
            let status = this.record;
            let rights = this.table.items;
            // <-- clear errors -->
            this.resetErrors();
            // <-- data -->
            let data = {
                'locale': this.$i18n.locale,
                'status': status,
                'rights': rights
            };
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.post(this.uri.main, data, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                });
        },
        baseUpdate() {
            this.showOverlay(true);
            // <--  -->
            let status = this.record;
            let rights = this.table.items;
            // <-- clear errors -->
            this.resetErrors();
            // <-- data -->
            let data = {
                'locale': this.$i18n.locale,
                'status': status,
                'rights': rights
            };
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.put(this.uri.main + `/${this.record.id}`, data, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                });
        },
    },
    computed: {
        title: {
            get() {
                let status = this.record;
                if (this.form.status_id <= 0 || status === null) {
                    return this.$t('form.add') + ': ' + this.$tc(this.localeKey, 0).toLowerCase();
                }
                // <-- status name -->
                let statusName = '';
                statusName = status.meta.name;
                Object.keys(status.locale).find((n) => {
                    if (n === this.$i18n.locale) {
                        if (!this.__isNull(status.locale[n].name)) {
                            statusName = status.locale[n].name;
                        }
                    }
                });
                // <--  -->
                return this.$tc(this.localeKey, 0) + ': ' + statusName;
            }
        },
        // <-- table -->
        tableItems() {
            let accesses = this.data.accesses;
            // <-- check if exists -->
            if (accesses === undefined) {
                return [];
            }
            let status_id = this.form.status_id;
            let rights = this.data.rights;
            // <--  -->
            let items = [];
            // <-- add items -->
            let accessName = '';
            let read = false;
            let write = false;
            let publish = false;
            let edit = false;
            let moderator = false;
            accesses.forEach(access => {
                // <-- access name -->
                accessName = access.meta.name;
                Object.keys(access.locale).find((n) => {
                    if (n === this.$i18n.locale) {
                        if (!this.__isNull(access.locale[n].name)) {
                            accessName = access.locale[n].name;
                        }
                    }
                });
                // <-- read, write -->
                read = false;
                write = false;
                publish = false;
                edit = false;
                moderator = false;
                rights.find((n) => {
                    if (n.status_id === status_id && n.access_id === access.id) {
                        read = n.right.read !== undefined ? n.right.read : false;
                        write = n.right.write !== undefined ? n.right.write : false;
                        publish = n.right.publish !== undefined ? n.right.publish : false;
                        edit = n.right.edit !== undefined ? n.right.edit : false;
                        moderator = n.right.moderator !== undefined ? n.right.moderator : false;
                    }
                });
                // <-- item -->
                items.push({
                    access_id: access.id,
                    'access': this.__capitalize(accessName),
                    'read': read,
                    'write': write,
                    'publish': publish,
                    'edit': edit,
                    'moderator': moderator,
                    locale: access.locale,
                });
            });
            return items;
        },
        // <-- locale -->
        setRecordLocale() {
            this.data.locale.forEach((value, index) => {
                // <-- add missing locale record -->
                if (Object.keys(this.record.locale).indexOf(value.locale) === -1) {
                    this.$set(this.record.locale, value.locale, {
                        name: '',
                        notes: ''
                    });
                }
            });
            return this.record.locale;
        },
        // <-- meta -->
        valid_metaStatusName() {
            if (this.valid === null) {
                return null;
            }
            return this.record.meta.name.length > 0 ? null : false;
        },
        // <-- valid foreign data -->
        valid_state() {
            if (this.valid === null) {
                return null;
            }
            return this.record.state_id > 0 ? null : false;
        },
        // <-- tabs valid -->
        tabValidBasic() {
            if (this.valid === null) {
                return true;
            }
            // <-- check validity -->
            return this.valid_metaStatusName === null ? true : this.valid_metaStatusName;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
